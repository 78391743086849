import { getParent, Instance, types } from "mobx-state-tree";
import { isNumeric } from "../../functions/IsNumeric";
import { SettingsAttributeThresholdStore } from "../SettingsAttributeThresholdStore";
import { SettingsStore } from "../SettingsStore";

export const DecimalStore = types
  .model("DecimalStore", {
    id: types.string,
    type: types.literal("decimal"),
    name: types.string,
    full_name: types.maybe(types.string),
    attribute_base: types.optional(types.string, "0"),
    cost_per_point: types.maybe(types.number),
  })
  .views((self) => ({
    get isPrimary(): boolean {
      return isNumeric(self.attribute_base);
    },
    get isSecondary(): boolean {
      return !isNumeric(self.attribute_base);
    },
    get isPool(): boolean {
      return false;
    },
  }));
