.container {
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
}

.defaultColor {
}

.editableColor {
  color: #0000a0;
}

.notEditableColor {
  color: #0000a099;
}
