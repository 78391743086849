import { PropsWithChildren } from "react";
import * as styles from "./Th.module.less";
import { observer } from "mobx-react-lite";
interface IProps {
  colSpan?: number;
}
export const Th = observer(function Th(props: PropsWithChildren<IProps>) {
  return (
    <th className={styles.container} colSpan={props.colSpan}>
      {props.children}
    </th>
  );
});
