import { Instance, types } from "mobx-state-tree";
import { DisplayUnion } from "./DisplayUnion";
import { SettingsBodyTypeStore } from "./SettingsBodyTypeStore";
import { SettingsPageStore } from "./SettingsPageStore";
import { SettingsAttributeUnion } from "./SettingsAttribute/SettingsAttributeUnion";
import { ObservableMap } from "mobx";
import { evaluate, number } from "mathjs";

export const SettingsStore = types
  .model("SettingsStore", {
    page: SettingsPageStore,
    block_layout: types.array(types.string),
    attributes: types.array(SettingsAttributeUnion),
    body_type: SettingsBodyTypeStore,
    damage_progression: types.string,
    default_length_units: types.string,
    default_weight_units: types.string,
    user_description_display: DisplayUnion,
    modifiers_display: DisplayUnion,
    notes_display: DisplayUnion,
    skill_level_adj_display: DisplayUnion,
    show_spell_adj: types.boolean,
    exclude_unspent_points_from_total: types.boolean,
  })
  .views((self) => ({
    get attributesMap(): ObservableMap<
      string,
      Instance<typeof SettingsAttributeUnion>
    > {
      return self.attributes.reduce<
        ObservableMap<string, Instance<typeof SettingsAttributeUnion>>
      >((acc, cur) => {
        acc.set(cur.id, cur);
        return acc;
      }, new ObservableMap());
    },
  }));
