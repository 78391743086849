import { types } from "mobx-state-tree";
import { ModifierStore } from "./ModifierStore";
import { WeaponUnion } from "./WeaponUnion";
import { FeatureUnion } from "./Feature/FeatureUnion";

export const TraitStore = types
  .model("TraitStore", {
    id: types.string,
    type: types.literal("trait"),
    name: types.string,
    reference: types.string,
    notes: types.maybe(types.string),
    weapons: types.array(WeaponUnion),
    tags: types.array(types.string),
    levels: types.optional(types.number, 0),
    points_per_level: types.optional(types.number, 0),
    can_level: types.optional(types.boolean, false),
    features: types.array(FeatureUnion),
    modifiers: types.array(ModifierStore),
    base_points: types.optional(types.number, 0),
    disabled: types.optional(types.boolean, false),
    cr: types.maybe(types.number),
    calc: types.model({
      points: types.number,
    }),
  })
  .views((self) => ({
    get points() {
      const modifierPoints = self.modifiers.reduce((acc, cur) => {
        if (!cur.disabled && cur.cost_type === "points") {
          acc += cur.cost;
        }
        return acc;
      }, 0);
      const modifierFactor = self.modifiers.reduce((acc, cur) => {
        if (!cur.disabled && cur.cost_type === undefined) {
          if (acc === 0) {
            acc = cur.cost / 100;
          } else {
            acc *= cur.cost / 100;
          }
        }
        return acc;
      }, 0);

      const cost = self.base_points + self.levels * self.points_per_level;
      const reduction = cost * modifierFactor;

      return cost + reduction + modifierPoints;
    },
  }))
  .views((self) => ({
    get advantagePoints() {
      if (self.points < 0) {
        return 0;
      }
      return self.points;
    },
    get disadvantagePoints() {
      if (self.points > 0) {
        return 0;
      }
      return self.points;
    },
  }));
