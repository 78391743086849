import { types } from "mobx-state-tree";
import { QualifierStore } from "../QualifierStore";

export const WeaponBonusStore = types.model("WeaponBonusStore", {
  type: types.literal("weapon_bonus"),
  selection_type: types.union(
    types.literal("weapons_with_name"),
    types.literal("weapons_with_required_skill"),
  ),
  specialization: types.maybe(QualifierStore),
  amount: types.number,
});
