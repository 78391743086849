import { attributes } from "./attributes";
import { MainStore } from "../Store/MainStore";
import { Instance } from "mobx-state-tree";
import { drs } from "./drs";
import { equipments } from "./equipments";

export function autoruns(store: Instance<typeof MainStore>) {
  attributes(store);
  drs(store);
  equipments(store);
}
