import { types } from "mobx-state-tree";
import { QualifierStore } from "./QualifierStore";

export const ContainedWeightPrereqStore = types.model(
  "ContainedWeightPrereqStore",
  {
    type: types.literal("contained_weight_prereq"),
    has: types.boolean,
    qualifier: QualifierStore,
  },
);
