import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Instance } from "mobx-state-tree";
import { TraitUnion } from "../Store/TraitUnion";
import { Td } from "../Elements/Table/Td";
import { Fragment } from "react";
import { TraitContainerStore } from "../Store/TraitContainerStore";
import { TraitStore } from "../Store/TraitStore";
import { observer } from "mobx-react-lite";
import { fixReference } from "../functions/fixReference";

const TraitRow = observer(function TraitRow(props: {
  trait: Instance<typeof TraitStore>;
  level: number;
}) {
  const subtext = [];
  if (props.trait.cr) {
    subtext.push(`CR: ${props.trait.cr}`);
  }
  for (const modifier of props.trait.modifiers) {
    if (!modifier.disabled) {
      subtext.push(modifier.name);
    }
  }
  const subTextJoined = subtext.join("; ");

  return (
    <Tr>
      <Td style={{ paddingLeft: 8 * props.level }}>
        <b>{props.trait.name}</b>
        <br />
        {subTextJoined && (
          <>
            {subTextJoined}
            <br />
          </>
        )}
        {props.trait.notes}
      </Td>
      <Td>
        <b>{props.trait.points}</b>
      </Td>
      <Td>
        <b>{fixReference(props.trait.reference)}</b>
      </Td>
    </Tr>
  );
});

const TraitContainerRow = observer(function TraitContainerRow(props: {
  trait: Instance<typeof TraitContainerStore>;
  level: number;
}) {
  return (
    <Tr>
      <Td style={{ paddingLeft: 8 * props.level }}>
        <b>{props.trait.name}</b>
        <br />
        {props.trait.notes}
      </Td>
      <Td>
        <b>{props.trait.points}</b>
      </Td>
      <Td>
        <b>{fixReference(props.trait.reference)}</b>
      </Td>
    </Tr>
  );
});

const TraitRenderer = observer(function TraitRenderer(props: {
  data: Array<Instance<typeof TraitUnion>>;
  level: number;
}) {
  const rows = props.data.map((elem) => {
    if (elem.type === "trait") {
      return <TraitRow key={elem.id} trait={elem} level={props.level} />;
    }
    return (
      <Fragment key={elem.id}>
        <TraitContainerRow trait={elem} level={props.level} />
        <TraitRenderer data={elem.children} level={props.level + 1} />
      </Fragment>
    );
  });
  return <>{rows}</>;
});

export const TraitsContainer = observer(function TraitsContainer(props: {
  data: Array<Instance<typeof TraitUnion>>;
}) {
  return (
    <Table style={{ gridArea: "traits" }}>
      <thead>
        <Tr>
          <Th>Trait</Th>
          <Th>Pts</Th>
          <Th>X</Th>
        </Tr>
      </thead>
      <tbody>
        <TraitRenderer data={props.data} level={0} />
      </tbody>
    </Table>
  );
});
