import { evaluate } from "mathjs";

export function evaluateExpression(
  expression: string,
  fetchVariable: (id: string) => number,
): number {
  const matches: string[] =
    expression.match(/[$][a-z0-9_]+/gi)?.map((match) => match.slice(1)) || [];
  const context = new Map<string, number>();
  for (const match of matches) {
    context.set(`$${match}`, fetchVariable(match));
  }
  return evaluate(expression, context);
}
