import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Instance } from "mobx-state-tree";
import { Td } from "../Elements/Table/Td";
import { SpellStore } from "../Store/SpellStore";
import { observer } from "mobx-react-lite";
import { fixReference } from "../functions/fixReference";

const SpellRow = observer(function SpellRow(props: {
  skill: Instance<typeof SpellStore>;
  level: number;
}) {
  return (
    <Tr>
      <Td style={{ paddingLeft: 8 * props.level }}>
        <b>{props.skill.name}</b>
        <br />
        {props.skill.notes}
      </Td>
      <Td>
        <b>{props.skill.calc?.level}</b>
      </Td>
      <Td>
        <b>{props.skill.calc?.rsl}</b>
      </Td>
      <Td>
        <b>{props.skill.points}</b>
      </Td>
      <Td>
        <b>{fixReference(props.skill.reference)}</b>
      </Td>
    </Tr>
  );
});

const SpellRenderer = observer(function SpellRenderer(props: {
  data: Array<Instance<typeof SpellStore>>;
  level: number;
}) {
  const rows = props.data.map((elem) => {
    return <SpellRow key={elem.id} skill={elem} level={props.level} />;
  });
  return <>{rows}</>;
});

export const SpellsContainer = observer(function SpellsContainer(props: {
  data: Array<Instance<typeof SpellStore>>;
}) {
  if (props.data.length <= 0) {
    return null;
  }

  return (
    <Table style={{ gridArea: "spells" }}>
      <thead>
        <Tr>
          <Th>Spell</Th>
          <Th>SL</Th>
          <Th>RSL</Th>
          <Th>Pts</Th>
          <Th>X</Th>
        </Tr>
      </thead>
      <tbody>
        <SpellRenderer data={props.data} level={0} />
      </tbody>
    </Table>
  );
});
