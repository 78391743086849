import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { ReactElement } from "react";
import { EquipmentStore } from "../Store/EquipmentStore";
import { Td } from "../Elements/Table/Td";
import { MeleeWeaponStore } from "../Store/MeleeWeaponStore";
import { TraitStore } from "../Store/TraitStore";
import { TechniqueStore } from "../Store/TechniqueStore";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof GcsStore>;
}

const EquipmentRow = observer(function EquipmentRow(props: {
  equipment: Instance<typeof EquipmentStore>;
  weapon: Instance<typeof MeleeWeaponStore>;
}) {
  return (
    <Tr>
      <Td>
        <b>{props.equipment.description}</b>
        <br />
        {props.equipment.notes}
      </Td>
      <Td bold>{props.weapon.usage}</Td>
      <Td bold>{props.weapon.calc.level}</Td>
      <Td bold>{props.weapon.calc.parry}</Td>
      <Td bold>{props.weapon.calc.block}</Td>
      <Td bold>{props.weapon.calc.damage}</Td>
      <Td bold>{props.weapon.reach}</Td>
      <Td bold>{props.weapon.strength}</Td>
    </Tr>
  );
});

const TraitRow = observer(function TraitRow(props: {
  trait: Instance<typeof TraitStore>;
  weapon: Instance<typeof MeleeWeaponStore>;
}) {
  return (
    <Tr>
      <Td>
        <b>{props.trait.name}</b>
      </Td>
      <Td bold>{props.weapon.usage}</Td>
      <Td bold>{props.weapon.calc.level}</Td>
      <Td bold>{props.weapon.calc.parry}</Td>
      <Td bold>{props.weapon.calc.block}</Td>
      <Td bold>{props.weapon.calc.damage}</Td>
      <Td bold>{props.weapon.reach}</Td>
      <Td bold>{props.weapon.strength}</Td>
    </Tr>
  );
});

const TechniqueRow = observer(function TechniqueRow(props: {
  technique: Instance<typeof TechniqueStore>;
  weapon: Instance<typeof MeleeWeaponStore>;
}) {
  return (
    <Tr>
      <Td>
        <b>{props.technique.name}</b>
      </Td>
      <Td bold>{props.weapon.usage}</Td>
      <Td bold>{props.weapon.calc.level}</Td>
      <Td bold>{props.weapon.calc.parry}</Td>
      <Td bold>{props.weapon.calc.block}</Td>
      <Td bold>{props.weapon.calc.damage}</Td>
      <Td bold>{props.weapon.reach}</Td>
      <Td bold>{props.weapon.strength}</Td>
    </Tr>
  );
});

export const MeleeContainer = observer(function MeleeContainer(props: IProps) {
  const rows: ReactElement[] = [];
  for (const equipment of props.store.weapons) {
    for (const weapon of equipment.weapons) {
      if (weapon.type === "melee_weapon") {
        if (equipment.type === "equipment") {
          rows.push(
            <EquipmentRow
              key={weapon.id}
              equipment={equipment}
              weapon={weapon}
            />,
          );
        }
        if (equipment.type === "trait") {
          rows.push(
            <TraitRow key={weapon.id} trait={equipment} weapon={weapon} />,
          );
        }
        if (equipment.type === "technique") {
          rows.push(
            <TechniqueRow
              key={weapon.id}
              technique={equipment}
              weapon={weapon}
            />,
          );
        }
      }
    }
  }

  if (rows.length <= 0) {
    return null;
  }

  return (
    <Table style={{ gridArea: "melee" }}>
      <thead>
        <Tr>
          <Th>Melee Weapon</Th>
          <Th>Usage</Th>
          <Th>SL</Th>
          <Th>Parry</Th>
          <Th>Block</Th>
          <Th>Damage</Th>
          <Th>Reach</Th>
          <Th>ST</Th>
        </Tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
});
