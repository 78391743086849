import { types } from "mobx-state-tree";
import { EquipmentContainerStore } from "./EquipmentContainerStore";
import { EquipmentStore } from "./EquipmentStore";

export const EquipmentUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "equipment") {
        return EquipmentStore;
      }
      if (snapshot.type === "equipment_container") {
        return EquipmentContainerStore;
      }
      return EquipmentStore;
    },
  },
  EquipmentStore,
  EquipmentContainerStore,
);
