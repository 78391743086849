import { types } from "mobx-state-tree";
import { PrereqListStore } from "./PrereqListStore";

export const SpellStore = types.model({
  id: types.string,
  type: types.literal("spell"),
  name: types.string,
  reference: types.maybe(types.string),
  notes: types.maybe(types.string),
  tags: types.array(types.string),
  difficulty: types.string,
  college: types.array(types.string),
  power_source: types.string,
  spell_class: types.string,
  casting_cost: types.string,
  maintenance_cost: types.string,
  casting_time: types.string,
  duration: types.string,
  points: types.number,
  prereqs: PrereqListStore,
  calc: types.model({ level: types.number, rsl: types.string }),
});
