import { Instance } from "mobx-state-tree";
import { NoteUnion } from "../Store/NoteUnion";
import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Td } from "../Elements/Table/Td";
import { Fragment } from "react";
import { observer } from "mobx-react-lite";

const NoteRenderer = observer(function NoteRenderer(props: {
  data: Array<Instance<typeof NoteUnion>>;
  level: number;
}) {
  let offsetString = "";

  const rows = props.data.map((elem) => {
    const notePart = (
      <Tr>
        <Td style={{ paddingLeft: 8 * props.level }}>
          {offsetString}
          {elem.text}
        </Td>
        <Td>{elem.reference}</Td>
      </Tr>
    );

    if (elem.type === "note_container") {
      return (
        <Fragment key={elem.id}>
          {notePart}
          <NoteRenderer data={elem.children} level={props.level + 1} />
        </Fragment>
      );
    }

    return <Fragment key={elem.id}>{notePart}</Fragment>;
  });
  return <>{rows}</>;
});

export const NoteContainer = observer(function NoteContainer(props: {
  data: Array<Instance<typeof NoteUnion>>;
}) {
  if (props.data.length <= 0) {
    return null;
  }

  return (
    <Table style={{ gridArea: "notes" }}>
      <thead>
        <Tr>
          <Th>Notes</Th>
          <Th>X</Th>
        </Tr>
      </thead>
      <tbody>
        <NoteRenderer data={props.data} level={0} />
      </tbody>
    </Table>
  );
});
