import { cast, SnapshotIn, types } from "mobx-state-tree";
import { GcsStore } from "./GcsStore";

export const MainStore = types
  .model("MainStore", {
    data: types.maybe(GcsStore),
  })
  .actions((self) => ({
    setData: (data: SnapshotIn<typeof GcsStore>): void => {
      self.data = cast(data);
    },
  }));
