import { types } from "mobx-state-tree";

export const SettingsPageStore = types.model("SettingsPageStore", {
  paper_size: types.string,
  orientation: types.string,
  top_margin: types.string,
  left_margin: types.string,
  bottom_margin: types.string,
  right_margin: types.string,
});
