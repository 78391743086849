import { types } from "mobx-state-tree";

export const SettingsAttributeThresholdStore = types.model(
  "SettingsAttributeThresholdStore",
  {
    state: types.string,
    expression: types.string,
    explanation: types.maybe(types.string),
    ops: types.maybe(types.array(types.string)),
  },
);
