import { types } from "mobx-state-tree";

export const QualifierStore = types.model("QualifierStore", {
  compare: types.union(
    types.literal("is"),
    types.literal("contains"),
    types.literal("at_least"),
    types.literal("starts_with"),
    types.literal("at_most"),
  ),
  qualifier: types.union(types.string, types.number),
});
