import { types } from "mobx-state-tree";
import { NoteStore } from "./NoteStore";
import { NoteContainerStore } from "./NoteContainerStore";

export const NoteUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "note") {
        return NoteStore;
      }
      if (snapshot.type === "note_container") {
        return NoteContainerStore;
      }
      return NoteStore;
    },
  },
  NoteStore,
  NoteContainerStore,
);
