const basicThrust: { [key: string]: string } = {
  1: "1d-6",
  3: "1d-5",
  5: "1d-4",
  7: "1d-3",
  9: "1d-2",
  11: "1d-1",
  13: "1d",
  15: "1d+1",
  17: "1d+2",
  19: "2d-1",
  21: "2d",
  23: "2d+1",
  25: "2d+2",
  27: "3d-1",
  29: "3d",
  31: "3d+1",
  33: "3d+2",
  35: "4d-1",
  37: "4d",
  39: "4d+1",
  45: "5d",
  50: "5d+2",
  55: "6d",
  60: "7d-1",
  65: "7d+1",
  70: "8d",
  75: "8d+2",
  80: "9d",
  85: "9d+2",
  90: "10d",
  95: "10d+2",
  100: "11d",
};

export function getThrust(entryStrength: number): string {
  let lastKey: number | undefined = undefined;
  for (const key of Object.keys(basicThrust).map(Number)) {
    if (entryStrength < key) {
      return lastKey ? basicThrust[lastKey] : "";
    }
    lastKey = key;
  }
  return "";
}
