import { Instance } from "mobx-state-tree";
import { MainStore } from "../Store/MainStore";
import { autorun } from "mobx";
import { evaluateExpression } from "../functions/evaluateExpression";
import { GcsStore } from "../Store/GcsStore";
import { AttributeStore } from "../Store/AttributeStore";
import { getSwing } from "../data/basicSwing";
import { getThrust } from "../data/basicThrust";

function setAttributePoints(
  gcsStore: Instance<typeof GcsStore>,
  attribute: Instance<typeof AttributeStore>,
) {
  attribute.calc.setPoints(
    (attribute.settings?.cost_per_point || 0) * attribute.adj,
  );
}

function calcAttributeBonuses(gcsStore: Instance<typeof GcsStore>) {
  const bonuses = new Map<string, number>();
  // Attribute bonuses can be found in equipments and traits
  for (const equipment of gcsStore.equipmentFlat) {
    if (equipment.equipped === false) {
      // If the equipment is not equipped there's no attribute_bonus to calculate
      continue;
    }

    for (const feature of equipment.features) {
      if (feature.type !== "attribute_bonus") {
        // We only care about attribute_bonus here
        continue;
      }

      const previousBonus = bonuses.get(feature.attribute) || 0;

      if (feature.per_level) {
        // attribute_bonus can be per_level
        const level = 1;
        bonuses.set(feature.attribute, previousBonus + feature.amount * level);
      } else {
        bonuses.set(feature.attribute, previousBonus + feature.amount);
      }
    }
  }

  for (const trait of gcsStore.traitsFlat) {
    if (trait.disabled === true) {
      // If the trait is disabled there's no attribute_bonus to calculate
      continue;
    }

    for (const feature of trait.features) {
      if (feature.type !== "attribute_bonus") {
        // We only care about attribute_bonus here
        continue;
      }

      const previousBonus = bonuses.get(feature.attribute) || 0;

      if (feature.per_level) {
        // attribute_bonus can be per_level
        const level = trait.levels;
        bonuses.set(feature.attribute, previousBonus + feature.amount * level);
      } else {
        bonuses.set(feature.attribute, previousBonus + feature.amount);
      }
    }
  }

  for (const skill of gcsStore.skillsFlat) {
    for (const feature of skill.features) {
      if (feature.type !== "attribute_bonus") {
        // We only care about attribute_bonus here
        continue;
      }

      const previousBonus = bonuses.get(feature.attribute) || 0;

      if (feature.per_level) {
        // attribute_bonus can be per_level
        const level = 1;
        bonuses.set(feature.attribute, previousBonus + feature.amount * level);
      } else {
        bonuses.set(feature.attribute, previousBonus + feature.amount);
      }
    }
  }

  return bonuses;
}

function setAttributeValue(
  gcsStore: Instance<typeof GcsStore>,
  attribute: Instance<typeof AttributeStore>,
  attributeBonus: number,
) {
  const attributeBaseExpression = attribute.settings?.attribute_base || "0";
  const fetchVariable: (id: string) => number = (id) => {
    return gcsStore.attributesMap.get(id)?.calc.value || 0;
  };

  const calculatedValue = evaluateExpression(
    attributeBaseExpression,
    fetchVariable,
  );
  // Attribute Value Max
  attribute.calc.setValue(calculatedValue + attributeBonus + attribute.adj);
}

function setGlobalAttributes(
  gcsStore: Instance<typeof GcsStore>,
  attributeBonus: Map<string, number>,
) {
  const strength = gcsStore.attributesMap.get("st")?.calc.value || 0;
  gcsStore.calc.setSwing(getSwing(strength));
  gcsStore.calc.setThrust(getThrust(strength));
  const basicLiftLb = Math.round(strength ** 2 / 5);
  gcsStore.calc.setBasicLift(`${basicLiftLb} lb`);

  const moveBonus = attributeBonus.get("move") || 0;
  const basicMove =
    (gcsStore.attributesMap.get("basic_move")?.calc.value || 0) + moveBonus;
  const move = [
    basicMove,
    Math.floor(basicMove * 0.8),
    Math.floor(basicMove * 0.6),
    Math.floor(basicMove * 0.4),
    Math.floor(basicMove * 0.2),
  ];
  gcsStore.calc.setMove(move);
  const basicSpeed = gcsStore.attributesMap.get("basic_speed")?.calc.value || 0;
  const dodgeBonus = attributeBonus.get("dodge") || 0;
  const basicDodge = Math.floor(basicSpeed) + 3 + dodgeBonus;
  const dodge = [
    basicDodge,
    basicDodge - 1,
    basicDodge - 2,
    basicDodge - 3,
    basicDodge - 4,
  ];
  gcsStore.calc.setDodge(dodge);
}

export function attributes(store: Instance<typeof MainStore>) {
  autorun(() => {
    const gcsStore = store.data;
    if (!gcsStore) {
      return;
    }

    const attributeBonuses = calcAttributeBonuses(gcsStore);
    //console.log(attributeBonuses);

    for (const attribute of store.data?.attributes || []) {
      setAttributePoints(gcsStore, attribute);
      setAttributeValue(
        gcsStore,
        attribute,
        attributeBonuses.get(attribute.attr_id) || 0,
      );
    }
    setGlobalAttributes(gcsStore, attributeBonuses);
  });
}
