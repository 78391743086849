.outerContainer {
  border: 1px solid #2b2b2b;
}

.container {
  border-collapse: collapse;
  width: 100%;

  thead tr {
    border: none;
  }
}

.name {
  background-color: #2b2b2b;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
}
