import { ITypeUnion, types } from "mobx-state-tree";
import { NoteUnion } from "./NoteUnion";

export const NoteContainerStore = types.model("NoteContainerStore", {
  id: types.string,
  type: types.literal("note_container"),
  open: types.optional(types.boolean, true),
  children: types.array(types.late((): ITypeUnion<any, any, any> => NoteUnion)),
  text: types.string,
  reference: types.maybe(types.string), // TODO
});
