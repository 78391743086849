import { LeftSide } from "./LeftSide";
import { RightSide } from "./RightSide";
import { Instance } from "mobx-state-tree";

import { MainStore } from "./Store/MainStore";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof MainStore>;
  loadFile(file: File): void;
}
export const Main = observer(function Main(props: IProps) {
  return (
    <div style={{ display: "grid", gridAutoFlow: "column" }}>
      {props.store.data ? (
        <LeftSide data={props.store.data} />
      ) : (
        <div>NO DATA</div>
      )}
      <RightSide loadFile={props.loadFile} />
    </div>
  );
});
