import { CSSProperties, PropsWithChildren } from "react";
import * as styles from "./Table.module.less";
import { observer } from "mobx-react-lite";
interface IProps {
  name?: string;
  style?: CSSProperties;
}
export const Table = observer(function Table(props: PropsWithChildren<IProps>) {
  return (
    <div className={styles.outerContainer} style={props.style}>
      {props.name && <div className={styles.name}>{props.name}</div>}
      <table className={styles.container}>{props.children}</table>
    </div>
  );
});
