import { getParent, Instance, types } from "mobx-state-tree";
import { GcsStore } from "./GcsStore";

export const SettingsBodyTypeLocationStore = types
  .model("SettingsBodyTypeLocationStore", {
    id: types.string,
    choice_name: types.string,
    table_name: types.string,
    slots: types.maybe(types.number),
    hit_penalty: types.maybe(types.number),
    dr_bonus: types.optional(types.number, 0),
    description: types.maybe(types.string),
    calc: types
      .model({
        roll_range: types.string,
        dr: types.model({
          all: types.number,
        }),
      })
      .actions((self) => ({
        setDr: (value: number) => (self.dr.all = value),
      })),
  })
  .views((self) => ({
    get dr(): number {
      const gcsStore = getParent<Instance<typeof GcsStore>>(self, 4);
      const bonus = gcsStore.drBonuses.get(self.id) || 0;
      return self.dr_bonus + bonus;
    },
  }));
