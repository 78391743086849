import { types } from "mobx-state-tree";

export const ConditionalModifierStore = types.model(
  "ConditionalModifierStore",
  {
    type: types.literal("conditional_modifier"),
    situation: types.string,
    amount: types.number,
  },
);
