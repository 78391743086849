import { Instance, ITypeUnion, types } from "mobx-state-tree";
import { EquipmentUnion } from "./EquipmentUnion";
import { WeaponUnion } from "./WeaponUnion";
import { EquipmentStore } from "./EquipmentStore";
import { PrereqUnion } from "./PrereqUnion";
import { PrereqListStore } from "./PrereqListStore";

export const EquipmentContainerStore = types
  .model("EquipmentContainerStore", {
    id: types.string,
    type: types.literal("equipment_container"),
    description: types.string,
    legality_class: types.maybe(types.string),
    reference: types.maybe(types.string),
    notes: types.maybe(types.string),
    vtt_notes: types.maybe(types.string),
    tech_level: types.maybe(types.string),
    tags: types.array(types.string),
    open: types.optional(types.boolean, true),
    quantity: types.number,
    value: types.optional(types.number, 0),
    weight: types.maybe(types.string),
    prereqs: types.maybe(PrereqListStore),
    children: types.array(
      types.late((): ITypeUnion<any, any, any> => EquipmentUnion),
    ),
    equipped: types.optional(types.boolean, false),
    ignore_weight_for_skills: types.optional(types.boolean, false),
    calc: types
      .model({
        extended_value: types.number,
        extended_weight: types.string,
        extended_weight_for_skills: types.maybe(types.string),
      })
      .views((self) => ({
        get extendedWeightKg() {
          return parseFloat(self.extended_weight) / 2;
        },
      }))
      .actions((self) => ({
        setExtendedValue: (value: number) => (self.extended_value = value),
        setExtendedWeight: (value: string) => (self.extended_weight = value),
        setExtendedWeightForSkills: (value: string) =>
          (self.extended_weight_for_skills = value),
      })),
  })
  .views((self) => ({
    get weightKg(): number {
      return parseFloat(self.weight || "0") / 2;
    },
  }));
