import { types } from "mobx-state-tree";
import { ReactionBonusStore } from "./Feature/ReactionBonusStore";
import { SkillBonusStore } from "./Feature/SkillBonusStore";
import { ConditionalModifierStore } from "./Feature/ConditionalModifierStore";

export const ModifierStore = types.model("ModifierStore", {
  id: types.string,
  type: types.literal("modifier"),
  name: types.string,
  reference: types.maybe(types.string),
  cost: types.optional(types.number, 0),
  cost_type: types.maybe(
    types.union(types.literal("points"), types.literal("multiplier")),
  ),
  disabled: types.optional(types.boolean, false),
  features: types.array(
    types.union(
      {
        dispatcher: (snapshot) => {
          if (snapshot.type === "reaction_bonus") {
            return ReactionBonusStore;
          }
          if (snapshot.type === "skill_bonus") {
            return SkillBonusStore;
          }
          if (snapshot.type === "conditional_modifier") {
            return ConditionalModifierStore;
          }
          return ReactionBonusStore;
        },
      },
      ReactionBonusStore,
      SkillBonusStore,
      ConditionalModifierStore,
    ),
  ),
});
