import { types } from "mobx-state-tree";
import { DecimalStore } from "./DecimalStore";
import { IntegerStore } from "./IntegerStore";
import { PoolStore } from "./PoolStore";

export const SettingsAttributeUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "decimal") {
        return DecimalStore;
      } else if (snapshot.type === "integer") {
        return IntegerStore;
      } else if (snapshot.type === "pool") {
        return PoolStore;
      }
      return IntegerStore;
    },
  },
  DecimalStore,
  IntegerStore,
  PoolStore,
);
