import { Box } from "../Elements/Box";
import { observer } from "mobx-react-lite";

interface IProps {
  imageData: string;
}
export const Portrait = observer(function Portrait(props: IProps) {
  return (
    <Box name={"Portrait"}>
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        src={`data:image/jpeg;base64,${props.imageData}`}
        alt={"portrait"}
      />
    </Box>
  );
});
