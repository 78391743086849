import { types } from "mobx-state-tree";
import { SkillStore } from "./SkillStore";
import { SkillContainerStore } from "./SkillContainerStore";
import { TechniqueStore } from "./TechniqueStore";

export const SkillUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "skill") {
        return SkillStore;
      }
      if (snapshot.type === "skill_container") {
        return SkillContainerStore;
      }
      if (snapshot.type === "technique") {
        return TechniqueStore;
      }
      return SkillStore;
    },
  },
  SkillStore,
  SkillContainerStore,
  TechniqueStore,
);
