import { Box } from "../Elements/Box";
import { ReactElement } from "react";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { PointPoolRow } from "../Elements/PointPoolRow";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof GcsStore>;
}
export const PointPool = observer(function PointPool(props: IProps) {
  const attributes = props.store.attributes.reduce<ReactElement[]>(
    (acc, cur) => {
      const settingsAttribute = cur.settings;
      if (settingsAttribute?.isPool) {
        acc.push(
          <PointPoolRow
            key={cur.attr_id}
            fullName={settingsAttribute.full_name}
            name={settingsAttribute.name}
            current={cur.calc.current}
            value={cur.calc.value}
            threshold={cur.currentThreshold}
            points={cur.calc.points}
            onChangeCurrent={cur.calc.setCurrent}
            onChangeValue={cur.calc.setValue}
          />,
        );
      }
      return acc;
    },
    [],
  );

  return (
    <Box name={"Point Pools"}>
      <table>
        <tbody>{attributes}</tbody>
      </table>
    </Box>
  );
});
