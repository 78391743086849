import { ITypeUnion, types } from "mobx-state-tree";
import { SkillUnion } from "./SkillUnion";

export const SkillContainerStore = types
  .model("SkillContainerStore", {
    id: types.string,
    type: types.literal("skill_container"),
    open: types.boolean,
    children: types.array(
      types.late((): ITypeUnion<any, any, any> => SkillUnion),
    ),
    name: types.string,
    reference: types.maybe(types.string),
    notes: types.maybe(types.string),
    calc: types.maybe(types.model({})), // TODO
  })
  .views((self) => ({
    get points() {
      return self.children.reduce((acc, cur) => {
        acc += cur.points;
        return acc;
      }, 0);
    },
  }));
