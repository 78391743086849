import { Box } from "../Elements/Box";
import { Row } from "../Elements/Row";
import { Instance } from "mobx-state-tree";

import { ProfileStore } from "../Store/ProfileStore";
import { observer } from "mobx-react-lite";

interface IProps {
  profileData: Instance<typeof ProfileStore>;
}
export const Identity = observer(function Identity(props: IProps) {
  return (
    <Box name={"Identity"}>
      <table>
        <tbody>
          <Row
            name={"Name"}
            value={props.profileData.name}
            onChange={props.profileData.setName}
          />
          <Row
            name={"Title"}
            value={props.profileData.title}
            onChange={props.profileData.setTitle}
          />
          <Row
            name={"Organization"}
            value={props.profileData.organization}
            onChange={props.profileData.setOrganization}
          />
        </tbody>
      </table>
    </Box>
  );
});
