import { types } from "mobx-state-tree";
import { WeaponUnion } from "./WeaponUnion";
import { FeatureUnion } from "./Feature/FeatureUnion";

export const EquipmentStore = types
  .model("EquipmentStore", {
    id: types.string,
    type: types.literal("equipment"),
    description: types.string,
    legality_class: types.maybe(types.string),
    reference: types.maybe(types.string),
    vtt_notes: types.maybe(types.string),
    tech_level: types.maybe(types.string),
    notes: types.maybe(types.string),
    tags: types.array(types.string),
    rated_strength: types.maybe(types.number),
    quantity: types.optional(types.number, 0),
    value: types.optional(types.number, 0),
    weight: types.maybe(types.string),
    weapons: types.array(WeaponUnion),
    features: types.array(FeatureUnion),
    equipped: types.optional(types.boolean, false),
    ignore_weight_for_skills: types.maybe(types.boolean),
    calc: types
      .model({
        extended_value: types.number,
        extended_weight: types.string,
        extended_weight_for_skills: types.maybe(types.string),
      })
      .views((self) => ({
        get extendedWeightKg() {
          return parseFloat(self.extended_weight) / 2;
        },
      }))
      .actions((self) => ({
        setExtendedValue: (value: number) => (self.extended_value = value),
        setExtendedWeight: (value: string) => (self.extended_weight = value),
        setExtendedWeightForSkills: (value: string) =>
          (self.extended_weight_for_skills = value),
      })),
  })
  .views((self) => ({
    get weightKg(): number {
      return parseFloat(self.weight || "0") / 2;
    },
  }));
