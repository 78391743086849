import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Fragment } from "react";
import { Instance } from "mobx-state-tree";
import { EquipmentUnion } from "../Store/EquipmentUnion";
import { EquipmentStore } from "../Store/EquipmentStore";
import { EquipmentContainerStore as EquipmentContainerStore } from "../Store/EquipmentContainerStore";
import { Td } from "../Elements/Table/Td";
import { observer } from "mobx-react-lite";

const EquipmentRow = observer(function EquipmentRow(props: {
  equipment: Instance<typeof EquipmentStore>;
  level: number;
}) {
  return (
    <Tr>
      <Td textAlign={"center"}>X</Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.quantity}</b>
      </Td>
      <Td style={{ paddingLeft: 8 * props.level }}>
        <b>{props.equipment.description}</b>
      </Td>
      <Td></Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.tech_level}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.legality_class}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.value}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.weightKg} kg</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.calc.extended_value}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.calc.extendedWeightKg} kg</b>
      </Td>
      <Td>
        <b>{props.equipment.reference}</b>
      </Td>
    </Tr>
  );
});

const EquipmentContainerRow = observer(function EquipmentContainerRow(props: {
  equipment: Instance<typeof EquipmentContainerStore>;
  level: number;
}) {
  return (
    <Tr>
      <Td textAlign={"center"}>X</Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.quantity}</b>
      </Td>
      <Td style={{ paddingLeft: 8 * props.level }}>
        <b>{props.equipment.description}</b>
      </Td>
      <Td></Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.tech_level}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.legality_class}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.value}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.weightKg} kg</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.calc.extended_value}</b>
      </Td>
      <Td textAlign={"right"}>
        <b>{props.equipment.calc.extendedWeightKg} kg</b>
      </Td>
      <Td>
        <b>{props.equipment.reference}</b>
      </Td>
    </Tr>
  );
});

const EquipmentRenderer = observer(function EquipmentRenderer(props: {
  data: Array<Instance<typeof EquipmentUnion>>;
  level: number;
}) {
  if (props.data.length <= 0) {
    return null;
  }

  const rows = props.data.map((elem) => {
    if (elem.type === "equipment") {
      return (
        <EquipmentRow key={elem.id} equipment={elem} level={props.level} />
      );
    }
    return (
      <Fragment key={elem.id}>
        <EquipmentContainerRow equipment={elem} level={props.level} />
        <EquipmentRenderer data={elem.children} level={props.level + 1} />
      </Fragment>
    );
  });
  return <>{rows}</>;
});

export const EquipmentContainer = observer(function EquipmentContainer(props: {
  data: Array<Instance<typeof EquipmentUnion>>;
  weight: number;
  value: number;
}) {
  return (
    <Table style={{ gridArea: "equipment" }}>
      <thead>
        <Tr>
          <Th>X</Th>
          <Th>#</Th>
          <Th>Carried Equipment ({`${props.weight} kg; $${props.value}`})</Th>
          <Th>Uses</Th>
          <Th>TL</Th>
          <Th>LC</Th>
          <Th>X</Th>
          <Th>Y</Th>
          <Th>XX</Th>
          <Th>YY</Th>
          <Th>X</Th>
        </Tr>
      </thead>
      <tbody>
        <EquipmentRenderer data={props.data} level={0} />
      </tbody>
    </Table>
  );
});
