import { types } from "mobx-state-tree";
import { EquipmentContainerStore } from "./EquipmentContainerStore";
import { EquipmentStore } from "./EquipmentStore";
import { MeleeWeaponStore } from "./MeleeWeaponStore";
import { RangedWeaponStore } from "./RangedWeaponStore";

export const WeaponUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "ranged_weapon") {
        return RangedWeaponStore;
      }
      if (snapshot.type === "melee_weapon") {
        return MeleeWeaponStore;
      }
      return MeleeWeaponStore;
    },
  },
  MeleeWeaponStore,
  RangedWeaponStore,
);
