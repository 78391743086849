import { Box } from "../Elements/Box";
import { ReactElement } from "react";
import { AttributeRow } from "../Elements/AttributeRow";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { observer } from "mobx-react-lite";
import { AttributeStore } from "../Store/AttributeStore";

interface IProps {
  store: Instance<typeof GcsStore>;
}

const SecondaryAttributeRow = observer(function SecondaryAttributeRow(props: {
  store: Instance<typeof AttributeStore>;
}) {
  return (
    <AttributeRow
      fullName={props.store.settings?.full_name}
      name={props.store.settings?.name || ""}
      value={props.store.calc.value}
      setValue={props.store.setValue}
      points={props.store.calc.points}
    />
  );
});

export const SecondaryAttributes = observer(function SecondaryAttributes(
  props: IProps,
) {
  const attributes = props.store.attributes.reduce<ReactElement[]>(
    (acc, cur) => {
      const settingsAttribute = cur.settings;
      if (settingsAttribute?.isSecondary) {
        acc.push(<SecondaryAttributeRow key={cur.attr_id} store={cur} />);
      }
      return acc;
    },
    [],
  );

  return (
    <Box name={"Secondary Attribute"}>
      <table>
        <tbody>{attributes}</tbody>
      </table>
    </Box>
  );
});
