import { Instance } from "mobx-state-tree";
import { MainStore } from "../Store/MainStore";
import { GcsStore } from "../Store/GcsStore";
import { autorun } from "mobx";
import { SettingsBodyTypeLocationStore } from "../Store/SettingsBodyTypeLocationStore";

function calcDrBonuses(gcsStore: Instance<typeof GcsStore>) {
  const bonuses = new Map<string, number>();
  // DR bonuses can be found in equipments and traits
  for (const equipment of gcsStore.equipmentFlat) {
    if (equipment.equipped === false) {
      // If the equipment is not equipped there's no dr_bonus to calculate
      continue;
    }

    for (const feature of equipment.features) {
      if (feature.type !== "dr_bonus") {
        // We only care about dr_bonus here
        continue;
      }

      const previousBonus = bonuses.get(feature.location) || 0;

      if (feature.per_level) {
        // dr_bonus can be per_level
        const level = 1;
        bonuses.set(feature.location, previousBonus + feature.amount * level);
      } else {
        bonuses.set(feature.location, previousBonus + feature.amount);
      }
    }
  }

  for (const trait of gcsStore.traitsFlat) {
    if (trait.disabled === true) {
      // If the trait is disabled there's no dr_bonus to calculate
      continue;
    }

    for (const feature of trait.features) {
      if (feature.type !== "dr_bonus") {
        // We only care about dr_bonus here
        continue;
      }

      const previousBonus = bonuses.get(feature.location) || 0;

      if (feature.per_level) {
        // dr_bonus can be per_level
        const level = trait.levels;
        bonuses.set(feature.location, previousBonus + feature.amount * level);
      } else {
        bonuses.set(feature.location, previousBonus + feature.amount);
      }
    }
  }

  for (const skill of gcsStore.skillsFlat) {
    for (const feature of skill.features) {
      if (feature.type !== "dr_bonus") {
        // We only care about dr_bonus here
        continue;
      }

      const previousBonus = bonuses.get(feature.location) || 0;

      if (feature.per_level) {
        // dr_bonus can be per_level
        const level = 1;
        bonuses.set(feature.location, previousBonus + feature.amount * level);
      } else {
        bonuses.set(feature.location, previousBonus + feature.amount);
      }
    }
  }

  return bonuses;
}

function setLocationDr(
  location: Instance<typeof SettingsBodyTypeLocationStore>,
  drBonuses: Map<string, number>,
) {
  const allBonus = drBonuses.get("all") || 0;
  const bonus = drBonuses.get(location.id) || 0;
  location.calc.setDr(location.dr_bonus + bonus + allBonus);
}

export function drs(store: Instance<typeof MainStore>) {
  autorun(() => {
    const gcsStore = store.data;
    if (!gcsStore) {
      return;
    }

    const drBonuses = calcDrBonuses(gcsStore);
    console.log(drBonuses);
    for (const location of gcsStore.settings.body_type.locations) {
      setLocationDr(location, drBonuses);
    }
  });
}
