.container {
}

.name {
  text-align: right;
}

.nameInverted {
  text-align: left;
}

.valueNonEditable {
  .valueEditable;
  opacity: 60%;
}

.valueEditable {
  color: #0000a0;
  font-weight: 500;
}
