import { types } from "mobx-state-tree";

export const RangedWeaponStore = types.model("RangedWeaponStore", {
  id: types.string,
  type: types.literal("ranged_weapon"),
  damage: types.model({
    type: types.string,
    st: types.maybe(types.string),
    base: types.maybe(types.string),
  }),
  strength: types.string,
  usage: types.maybe(types.string),
  accuracy: types.maybe(types.string),
  range: types.string,
  rate_of_fire: types.string,
  shots: types.string,
  bulk: types.string,
  recoil: types.maybe(types.string), //TODO
  defaults: types.array(
    types.model({
      type: types.string,
      name: types.maybe(types.string),
      modifier: types.maybe(types.number),
      specialization: types.maybe(types.string),
    }),
  ),
  calc: types.model({
    level: types.number,
    damage: types.string,
    range: types.maybe(types.string),
  }),
});
