import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Td } from "../Elements/Table/Td";
import { Th } from "../Elements/Table/Th";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { numberToString } from "../functions/numberToString";

interface IProps {
  store: Instance<typeof GcsStore>;
}

const levelNames = ["None", "Light", "Medium", "Heavy", "X-Heavy"];

const weightMultiplier = [1, 2, 3, 6, 10];
const Row = observer(function Row(props: {
  level: number;
  move: number;
  dodge: number;
  basicLift: number;
}) {
  const name = `${props.level} ${levelNames[props.level]}`;
  return (
    <Tr>
      <Td textAlign={"center"}>X</Td>
      <Td textAlign={"center"} bold>
        {props.level}
      </Td>
      <Td>{levelNames[props.level]}</Td>
      <Td textAlign={"right"} bold>
        {numberToString(props.basicLift * weightMultiplier[props.level])} kg
      </Td>
      <Td textAlign={"right"} bold>
        {props.move}
      </Td>
      <Td textAlign={"right"} bold>
        {props.dodge}
      </Td>
    </Tr>
  );
});

export const EncumbranceMoveDodge = observer(function EncumbranceMoveDodge(
  props: IProps,
) {
  const rows: ReactElement[] = [];
  for (let i = 0; i < props.store.calc.dodge.length; i++) {
    rows.push(
      <Row
        key={i}
        dodge={props.store.calc.dodge[i]}
        move={props.store.calc.move[i]}
        basicLift={props.store.calc.basicLiftKg}
        level={i}
      />,
    );
  }

  return (
    <Table name={"Encumbrance, Move & Dodge"}>
      <thead>
        <Tr>
          <Th colSpan={3}>Level</Th>
          <Th>Max Load</Th>
          <Th>Move</Th>
          <Th>Dodge</Th>
        </Tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
});
