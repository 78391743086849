import { types } from "mobx-state-tree";

export const MeleeWeaponStore = types.model("MeleeWeaponStore", {
  id: types.string,
  type: types.literal("melee_weapon"),
  damage: types.model({
    type: types.string,
    st: types.maybe(types.string),
    base: types.maybe(types.string),
  }),
  usage: types.maybe(types.string),
  usage_notes: types.maybe(types.string),
  reach: types.maybe(types.string),
  strength: types.maybe(types.string),
  parry: types.maybe(types.string),
  block: types.maybe(types.string),
  defaults: types.array(
    types.model({
      type: types.string,
      name: types.maybe(types.string),
      modifier: types.maybe(types.number),
      specialization: types.maybe(types.string),
    }),
  ),
  calc: types.model({
    level: types.maybe(types.number),
    damage: types.maybe(types.string),
    parry: types.maybe(types.string),
    block: types.maybe(types.string),
  }),
});
