import { ITypeUnion, types } from "mobx-state-tree";
import { TraitUnion } from "./TraitUnion";

export const TraitContainerStore = types
  .model("TraitContainerStore", {
    id: types.string,
    type: types.literal("trait_container"),
    open: types.optional(types.boolean, true),
    children: types.array(
      types.late((): ITypeUnion<any, any, any> => TraitUnion),
    ),
    reference: types.maybe(types.string),
    notes: types.maybe(types.string),
    name: types.string,
    calc: types.model({
      points: types.number,
    }),
  })
  .views((self) => ({
    get points() {
      return self.children.reduce((acc, cur) => {
        acc += cur.points;
        return acc;
      }, 0);
    },
    get advantagePoints() {
      return self.children.reduce((acc, cur) => {
        acc += cur.advantagePoints;
        return acc;
      }, 0);
    },
    get disadvantagePoints() {
      return self.children.reduce((acc, cur) => {
        acc += cur.disadvantagePoints;
        return acc;
      }, 0);
    },
  }));
