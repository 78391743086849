import { getParent, Instance, types } from "mobx-state-tree";
import { isNumeric } from "../../functions/IsNumeric";
import { SettingsAttributeThresholdStore } from "../SettingsAttributeThresholdStore";
import { SettingsStore } from "../SettingsStore";

export const PoolStore = types
  .model("PoolStore", {
    id: types.string,
    type: types.literal("pool"),
    name: types.string,
    full_name: types.maybe(types.string),
    attribute_base: types.optional(types.string, "0"),
    cost_per_point: types.maybe(types.number),
    cost_adj_percent_per_sm: types.maybe(types.number),
    thresholds: types.array(SettingsAttributeThresholdStore),
  })
  .views((self) => ({
    get isPrimary(): boolean {
      return false;
    },
    get isSecondary(): boolean {
      return false;
    },
    get isPool(): boolean {
      return true;
    },
  }));
