import { types } from "mobx-state-tree";
import { ConditionalModifierStore } from "./ConditionalModifierStore";
import { AttributeBonusStore } from "./AttributeBonusStore";
import { DrBonusStore } from "./DrBonusStore";
import { SkillBonusStore } from "./SkillBonusStore";
import { ReactionBonusStore } from "./ReactionBonusStore";
import { SpellBonusStore } from "./SpellBonusStore";
import { WeaponBonusStore } from "./WeaponBonusStore";

export const FeatureUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "conditional_modifier") {
        return ConditionalModifierStore;
      }
      if (snapshot.type === "attribute_bonus") {
        return AttributeBonusStore;
      }
      if (snapshot.type === "dr_bonus") {
        return DrBonusStore;
      }
      if (snapshot.type === "skill_bonus") {
        return SkillBonusStore;
      }
      if (snapshot.type === "reaction_bonus") {
        return ReactionBonusStore;
      }
      if (snapshot.type === "spell_bonus") {
        return SpellBonusStore;
      }
      if (snapshot.type === "weapon_bonus") {
        return WeaponBonusStore;
      }
      return ConditionalModifierStore;
    },
  },
  ConditionalModifierStore,
  AttributeBonusStore,
  DrBonusStore,
  SkillBonusStore,
  ReactionBonusStore,
  SpellBonusStore,
  WeaponBonusStore,
);
