import { Box } from "../Elements/Box";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Td } from "../Elements/Table/Td";
import { SettingsBodyTypeLocationStore } from "../Store/SettingsBodyTypeLocationStore";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof GcsStore>;
}

const Row = observer(function Row(props: {
  store: Instance<typeof SettingsBodyTypeLocationStore>;
}) {
  return (
    <Tr>
      <Td textAlign={"center"}>{props.store.calc.roll_range}</Td>
      <Td>{props.store.choice_name}</Td>
      <Td textAlign={"center"} bold color={"notEditable"}>
        {props.store.hit_penalty || "+0"}
      </Td>
      <Td textAlign={"center"} bold color={"notEditable"}>
        {props.store.calc.dr.all}
      </Td>
    </Tr>
  );
});

export const BodyType = observer(function BodyType(props: IProps) {
  const rows = props.store.settings.body_type.locations.map((val, index) => {
    return <Row key={index} store={val} />;
  });
  return (
    <Table name={props.store.settings.body_type.name}>
      <thead>
        <Tr>
          <Th>Roll</Th>
          <Th colSpan={2}>Location</Th>
          <Th>DR</Th>
        </Tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
});
