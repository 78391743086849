import { types } from "mobx-state-tree";

export const AttributeCalcStore = types
  .model("AttributeCalcStore", {
    value: types.number,
    current: types.maybe(types.number),
    points: types.number,
  })
  .actions((self) => ({
    setValue: (value?: number) => (self.value = value || 0),
    setCurrent: (value?: number) => (self.current = value),
    setPoints: (value: number) => (self.points = value),
  }));
