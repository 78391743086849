import { cast, Instance, types } from "mobx-state-tree";
import { NoteUnion } from "./NoteUnion";
import { TraitUnion } from "./TraitUnion";
import { SkillUnion } from "./SkillUnion";
import { EquipmentUnion } from "./EquipmentUnion";
import { EquipmentStore } from "./EquipmentStore";
import { TraitStore } from "./TraitStore";
import { TechniqueStore } from "./TechniqueStore";
import { ObservableMap } from "mobx";
import { SkillStore } from "./SkillStore";
import { AttributeStore } from "./AttributeStore";
import { SettingsStore } from "./SettingsStore";
import { ProfileStore } from "./ProfileStore";
import { PointsRecordStore } from "./PointsRecordStore";
import { SpellStore } from "./SpellStore";

export const GcsStore = types
  .model("GcsStore", {
    type: types.string,
    version: types.literal(4),
    id: types.string,
    total_points: types.number,
    points_record: types.array(PointsRecordStore),
    profile: ProfileStore,
    settings: SettingsStore,
    attributes: types.array(AttributeStore),
    traits: types.array(TraitUnion),
    skills: types.array(SkillUnion),
    spells: types.array(SpellStore),
    equipment: types.array(EquipmentUnion),
    other_equipment: types.array(EquipmentUnion),
    notes: types.array(NoteUnion),
    created_date: types.string,
    modified_date: types.string,
    calc: types
      .model({
        swing: types.string,
        thrust: types.string,
        basic_lift: types.string,
        dodge_bonus: types.maybe(types.number),
        parry_bonus: types.maybe(types.number),
        block_bonus: types.maybe(types.number),
        move: types.array(types.number),
        dodge: types.array(types.number),
      })
      .actions((self) => ({
        setSwing: (value: string) => (self.swing = value),
        setThrust: (value: string) => (self.thrust = value),
        setBasicLift: (value: string) => (self.basic_lift = value),
        setMove: (value: number[]) => (self.move = cast(value)),
        setDodge: (value: number[]) => (self.dodge = cast(value)),
      }))
      .views((self) => ({
        get basicLiftKg(): number {
          return parseInt(self.basic_lift) / 2;
        },
      }))
      .views((self) => ({
        get oneHandedLiftKg(): number {
          return self.basicLiftKg * 2;
        },
        get twoHandedLiftKg(): number {
          return self.basicLiftKg * 8;
        },
        get shoveKnockOverKg(): number {
          return self.basicLiftKg * 12;
        },
        get runningShoveKnockOverKg(): number {
          return self.basicLiftKg * 24;
        },
        get carryOnBackKg(): number {
          return self.basicLiftKg * 15;
        },
        get shiftSlightlyKg(): number {
          return self.basicLiftKg * 50;
        },
      })),
  })
  .views((self) => ({
    get attributesMap(): ObservableMap<
      string,
      Instance<typeof AttributeStore>
    > {
      return self.attributes.reduce<
        ObservableMap<string, Instance<typeof AttributeStore>>
      >((acc, cur) => {
        acc.set(cur.attr_id, cur);
        return acc;
      }, new ObservableMap());
    },
    get spentPointsAttributes(): number {
      return self.attributes.reduce((acc, cur) => {
        acc += cur.calc.points;
        return acc;
      }, 0);
    },
    get spentPointsTraits(): number {
      return self.traits.reduce((acc, cur) => {
        acc += cur.points;
        return acc;
      }, 0);
    },
    get spentPointsTraitsAdvantages(): number {
      return self.traits.reduce((acc, cur) => {
        acc += cur.advantagePoints;
        return acc;
      }, 0);
    },
    get spentPointsTraitsDisadvantages(): number {
      return self.traits.reduce((acc, cur) => {
        acc += cur.disadvantagePoints;
        return acc;
      }, 0);
    },
    get spentPointsSkills(): number {
      return self.skills.reduce((acc, cur) => {
        acc += cur.points;
        return acc;
      }, 0);
    },
    get spentPointsSpells(): number {
      return self.spells.reduce((acc, cur) => {
        acc += cur.points;
        return acc;
      }, 0);
    },
    get carriedEquipmentWeightKg() {
      return self.equipment.reduce((acc, cur) => {
        acc += parseFloat(cur.calc.extended_weight) / 2;
        return acc;
      }, 0);
    },
    get carriedEquipmentValue() {
      return self.equipment.reduce((acc, cur) => {
        acc += cur.calc.extended_value;
        return acc;
      }, 0);
    },
    get otherEquipmentValue() {
      return self.other_equipment.reduce((acc, cur) => {
        acc += cur.calc.extended_value;
        return acc;
      }, 0);
    },
    get equipmentFlat() {
      const recursion = (
        input: Array<Instance<typeof EquipmentUnion>>,
      ): Array<Instance<typeof EquipmentStore>> => {
        let temp: Array<Instance<typeof EquipmentStore>> = [];
        for (const element of input) {
          if (element.type === "equipment_container") {
            temp.push(...recursion(element.children));
          }
          if (element.type === "equipment") {
            temp.push(element);
          }
        }
        return temp;
      };
      return recursion(self.equipment);
    },
    get traitsFlat() {
      const recursion = (
        input: Array<Instance<typeof TraitUnion>>,
      ): Array<Instance<typeof TraitStore>> => {
        let temp: Array<Instance<typeof TraitStore>> = [];
        for (const element of input) {
          if (element.type === "trait_container") {
            temp.push(...recursion(element.children));
          }
          if (element.type === "trait") {
            temp.push(element);
          }
        }
        return temp;
      };
      return recursion(self.traits);
    },
    get skillsFlat() {
      const recursion = (
        input: Array<Instance<typeof SkillUnion>>,
      ): Array<Instance<typeof SkillStore>> => {
        let temp: Array<Instance<typeof SkillStore>> = [];
        for (const element of input) {
          if (element.type === "skill_container") {
            temp.push(...recursion(element.children));
          }
          if (element.type === "skill") {
            temp.push(element);
          }
        }
        return temp;
      };
      return recursion(self.skills);
    },
    get techniquesFlat() {
      const recursion = (
        input: Array<Instance<typeof SkillUnion>>,
      ): Array<Instance<typeof TechniqueStore>> => {
        let temp: Array<Instance<typeof TechniqueStore>> = [];
        for (const element of input) {
          if (element.type === "skill_container") {
            temp.push(...recursion(element.children));
          }
          if (element.type === "technique") {
            temp.push(element);
          }
        }
        return temp;
      };
      return recursion(self.skills);
    },
  }))
  .views((self) => ({
    get unspentPoints(): number {
      return (
        self.total_points -
        self.spentPointsAttributes -
        self.spentPointsTraitsDisadvantages -
        self.spentPointsTraitsAdvantages -
        self.spentPointsSkills -
        self.spentPointsSpells
      );
    },
    get weapons() {
      return [
        ...self.equipmentFlat,
        ...self.traitsFlat,
        ...self.techniquesFlat,
      ].reduce<
        Array<
          | Instance<typeof EquipmentStore>
          | Instance<typeof TraitStore>
          | Instance<typeof TechniqueStore>
        >
      >((acc, cur) => {
        if (cur.weapons) {
          acc.push(cur);
        }
        return acc;
      }, []);
    },
    get reactions(): ObservableMap<string, number> {
      const acc = new ObservableMap<string, number>();
      for (const trait of self.traitsFlat) {
        for (const modifier of trait.modifiers) {
          if (modifier.disabled) {
            continue;
          }

          for (const feature of modifier.features) {
            if (feature.type === "reaction_bonus") {
              acc.set(
                feature.situation,
                (acc.get(feature.situation) || 0) + feature.amount,
              );
            }
          }
        }
      }
      for (const trait of self.traitsFlat) {
        for (const feature of trait.features) {
          if (feature.type === "reaction_bonus") {
            acc.set(
              feature.situation,
              (acc.get(feature.situation) || 0) + feature.amount,
            );
          }
        }
      }
      for (const equipment of self.equipmentFlat) {
        if (equipment.type === "equipment") {
          for (const feature of equipment.features) {
            if (feature.type === "reaction_bonus") {
              acc.set(
                feature.situation,
                (acc.get(feature.situation) || 0) + feature.amount,
              );
            }
          }
        }
      }
      return acc;
    },
    get conditions(): ObservableMap<string, number> {
      const acc = new ObservableMap<string, number>();
      for (const equipment of self.equipmentFlat) {
        if (equipment.type === "equipment") {
          for (const feature of equipment.features) {
            if (feature.type === "conditional_modifier") {
              acc.set(
                feature.situation,
                (acc.get(feature.situation) || 0) + feature.amount,
              );
            }
          }
        }
      }
      for (const trait of self.traitsFlat) {
        for (const modifier of trait.modifiers) {
          if (modifier.disabled) {
            continue;
          }

          for (const feature of modifier.features) {
            if (feature.type === "conditional_modifier") {
              acc.set(
                feature.situation,
                (acc.get(feature.situation) || 0) + feature.amount,
              );
            }
          }
        }

        for (const feature of trait.features) {
          if (feature.type === "conditional_modifier") {
            acc.set(
              feature.situation,
              (acc.get(feature.situation) || 0) + feature.amount,
            );
          }
        }
      }
      return acc;
    },
    get drBonuses(): ObservableMap<string, number> {
      const acc = new ObservableMap<string, number>();
      for (const equipment of self.equipmentFlat) {
        for (const feature of equipment.features) {
          if (feature.type === "dr_bonus") {
            acc.set(
              feature.location,
              (acc.get(feature.location) || 0) + feature.amount,
            );
          }
        }
      }
      return acc;
    },
  }));
