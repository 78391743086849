import { ProfileContainer } from "./Profile/ProfileContainer";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "./Store/GcsStore";
import { NoteContainer } from "./Notes/NotesContainer";
import { SpellsContainer } from "./Spells/SpellsContainer";
import { SkillsContainer } from "./Skills/SkillsContainer";
import { ConditionalModifiersContainer } from "./ConditionalModifiers/ConditionalModifiersContainer";
import { ReactionsContainer } from "./Reactions/ReactionsContainer";
import { OtherEquipmentContainer } from "./OtherEquipment/OtherEquipmentContainer";
import { EquipmentContainer } from "./Equipment/EquipmentContainer";
import { TraitsContainer } from "./Traits/TraitsContainer";
import { RangedContainer } from "./Ranged/RangedContainer";
import { MeleeContainer } from "./Melee/MeleeContainer";
import { observer } from "mobx-react-lite";

interface IProps {
  data: Instance<typeof GcsStore>;
}

export const LeftSide = observer(function LeftSide(props: IProps) {
  const maxColumnCount = props.data.settings.block_layout.reduce((acc, cur) => {
    const columnCount = cur.split(" ").length;
    if (columnCount > acc) {
      acc = columnCount;
    }
    return acc;
  }, 0);
  const gridLayout = ["profile", ...props.data.settings.block_layout].reduce<
    string[]
  >((acc, cur) => {
    const columns = cur.split(" ");
    const lastElementOfColumns = columns[columns.length - 1];
    while (columns.length < maxColumnCount) {
      columns.push(lastElementOfColumns);
    }
    acc.push('"' + columns.join(" ") + '"');
    return acc;
  }, []);

  return (
    <div
      style={{
        width: "21cm",
        display: "grid",
        gap: 1,
        gridTemplateAreas: gridLayout.join("\n"),
      }}
    >
      <ProfileContainer store={props.data} />
      <ConditionalModifiersContainer data={props.data.conditions} />
      <EquipmentContainer
        data={props.data.equipment}
        value={props.data.carriedEquipmentValue}
        weight={props.data.carriedEquipmentWeightKg}
      />
      <MeleeContainer store={props.data} />
      <NoteContainer data={props.data.notes} />
      <OtherEquipmentContainer
        data={props.data.other_equipment}
        value={props.data.otherEquipmentValue}
      />
      <RangedContainer store={props.data} />
      <ReactionsContainer data={props.data.reactions} />
      <TraitsContainer data={props.data.traits} />
      <SkillsContainer data={props.data.skills} />
      <SpellsContainer data={props.data.spells} />
    </div>
  );
});
