import { Portrait } from "./Portrait";
import { Description } from "./Description";
import { Identity } from "./Identity";
import { Miscellaneous } from "./Miscellaneous";
import { Points } from "./Points";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { PrimaryAttributes } from "./PrimaryAttributes";
import { BasicDamage } from "./BasicDamage";
import { SecondaryAttributes } from "./SecondaryAttributes";
import { PointPool } from "./PointPool";
import { BodyType } from "./BodyType";
import { EncumbranceMoveDodge } from "./EncumbranceMoveDodge";
import { LiftingMoving } from "./LiftingMoving";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof GcsStore>;
}

const ProfileContainer1 = observer(function ProfileContainer1(props: IProps) {
  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "15fr 47fr 12fr", gap: 1 }}
    >
      <Portrait imageData={props.store.profile.portrait} />
      <div style={{ display: "grid", gap: 1 }}>
        <div
          style={{ display: "grid", gridTemplateColumns: "28fr 19fr", gap: 1 }}
        >
          <Identity profileData={props.store.profile} />
          <Miscellaneous store={props.store} />
        </div>
        <Description profileData={props.store.profile} />
      </div>
      <Points store={props.store} />
    </div>
  );
});

const ProfileContainer2 = observer(function ProfileContainer2(props: IProps) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "327fr 160fr 253fr",
        gap: 1,
      }}
    >
      <div style={{ display: "grid", gap: 1 }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "174fr 153fr",
            gap: 1,
          }}
        >
          <div style={{ display: "grid", gap: 1 }}>
            <PrimaryAttributes store={props.store} />
            <BasicDamage store={props.store} />
          </div>
          <SecondaryAttributes store={props.store} />
        </div>
        <PointPool store={props.store} />
      </div>
      <BodyType store={props.store} />
      <div style={{ display: "grid", gap: 1 }}>
        <EncumbranceMoveDodge store={props.store} />
        <LiftingMoving store={props.store} />
      </div>
    </div>
  );
});

export const ProfileContainer = observer(function ProfileContainer(
  props: IProps,
) {
  return (
    <div style={{ display: "grid", gap: 1, gridArea: "profile" }}>
      <ProfileContainer1 {...props} />
      <ProfileContainer2 {...props} />
    </div>
  );
});
