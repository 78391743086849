import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Table } from "../Elements/Table/Table";
import { ObservableMap, values } from "mobx";
import { Td } from "../Elements/Table/Td";
import { observer } from "mobx-react-lite";

const ReactionRow = observer(function ReactionRow(props: {
  name: string;
  value: number;
}) {
  return (
    <Tr>
      <Td bold textAlign={"right"}>
        {props.value >= 0 ? `+${props.value}` : props.value}
      </Td>
      <Td bold>{props.name}</Td>
    </Tr>
  );
});

const ReactionRenderer = observer(function ReactionRenderer(props: {
  data: ObservableMap<string, number>;
}) {
  return Array.from(props.data.entries()).map(([key, value]) => {
    return <ReactionRow key={key} name={key} value={value} />;
  });
});

export const ReactionsContainer = observer(function ReactionsContainer(props: {
  data: ObservableMap<string, number>;
}) {
  if (props.data.size <= 0) {
    return null;
  }

  return (
    <Table style={{ gridArea: "reactions" }}>
      <thead>
        <Tr>
          <Th>±</Th>
          <Th>Reaction</Th>
        </Tr>
      </thead>
      <tbody>
        <ReactionRenderer data={props.data} />
      </tbody>
    </Table>
  );
});
