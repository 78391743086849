const basicSwing: { [key: string]: string } = {
  1: "1d-5",
  3: "1d-4",
  5: "1d-3",
  7: "1d-2",
  9: "1d-1",
  10: "1d",
  11: "1d+1",
  12: "1d+2",
  13: "2d-1",
  14: "2d",
  15: "2d+1",
  16: "2d+2",
  17: "3d-1",
  18: "3d",
  19: "3d+1",
  20: "3d+2",
  21: "4d-1",
  22: "4d",
  23: "4d+1",
  24: "4d+2",
  25: "5d-1",
  26: "5d",
  27: "5d+1",
  29: "5d+2",
  31: "6d-1",
  33: "6d",
  35: "6d+1",
  37: "6d+2",
  39: "7d-1",
  45: "7d+1",
  50: "8d-1",
  55: "8d+1",
  60: "9d",
  65: "9d+2",
  70: "10d",
  75: "10d+2",
  80: "11d",
  85: "11d+2",
  90: "12d",
  95: "12d+2",
  100: "13d",
};

export function getSwing(entryStrength: number): string {
  let lastKey: number | undefined = undefined;
  for (const key of Object.keys(basicSwing).map(Number)) {
    if (entryStrength < key) {
      return lastKey ? basicSwing[lastKey] : "";
    }
    lastKey = key;
  }
  return "";
}
