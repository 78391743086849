import { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as styles from "./Input.module.less";

interface IProps {
  onChange: (value: string | undefined) => void;
  value: string | undefined;
}

export function TextInput(props: IProps) {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [props.onChange],
  );

  const onBlur = useCallback(() => {
    props.onChange(value);
  }, [value]);

  return (
    <input
      className={styles.container}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
}
