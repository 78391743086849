import { CSSProperties, PropsWithChildren } from "react";
import * as styles from "./Box.module.less";
import { observer } from "mobx-react-lite";
interface IProps {
  name: string;
  style?: CSSProperties;
}
export const Box = observer(function Box(props: PropsWithChildren<IProps>) {
  return (
    <div className={styles.container}>
      <div className={styles.name}>{props.name}</div>
      <div style={props.style}>{props.children}</div>
    </div>
  );
});
