import { types } from "mobx-state-tree";
import { TraitStore } from "./TraitStore";
import { TraitContainerStore } from "./TraitContainerStore";

export const TraitUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "trait") {
        return TraitStore;
      }
      if (snapshot.type === "trait_container") {
        return TraitContainerStore;
      }
      return TraitStore;
    },
  },
  TraitStore,
  TraitContainerStore,
);
