import * as styles from "./Row.module.less";
import { observer } from "mobx-react-lite";
import { TextInput } from "./TextInput";

interface IProps {
  inverted?: boolean;
  name: string;
  value: string | undefined;
  onChange?: (value: string | undefined) => void;
}
export const Row = observer(function Row(props: IProps) {
  if (props.inverted) {
    return (
      <tr>
        {props.onChange ? (
          <td className={styles.valueEditable}>
            <TextInput onChange={props.onChange} value={props.value} />
          </td>
        ) : (
          <td className={styles.valueNonEditable}>{props.value}</td>
        )}
        <td className={styles.nameInverted}>{props.name}</td>
      </tr>
    );
  }

  return (
    <tr>
      <td className={styles.name}>{props.name}</td>
      {props.onChange ? (
        <td className={styles.valueEditable}>
          <TextInput onChange={props.onChange} value={props.value} />
        </td>
      ) : (
        <td className={styles.valueNonEditable}>{props.value}</td>
      )}
    </tr>
  );
});
