import { types } from "mobx-state-tree";
import { ContainedWeightPrereqStore } from "./ContainedWeightPrereqStore";
import { TraitPrereqStore } from "./TraitPrereqStore";
import { AttributePrereqStore } from "./AttributePrereqStore";

export const PrereqUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (snapshot.type === "contained_weight_prereq") {
        return ContainedWeightPrereqStore;
      }
      if (snapshot.type === "trait_prereq") {
        return TraitPrereqStore;
      }
      if (snapshot.type === "attribute_prereq") {
        return AttributePrereqStore;
      }
      return ContainedWeightPrereqStore;
    },
  },
  ContainedWeightPrereqStore,
  TraitPrereqStore,
  AttributePrereqStore,
);
