import { types } from "mobx-state-tree";
import { QualifierStore } from "../QualifierStore";

export const SkillBonusStore = types.model("SkillBonusStore", {
  type: types.literal("skill_bonus"),
  selection_type: types.literal("skills_with_name"),
  name: QualifierStore,
  amount: types.number,
  per_level: types.optional(types.boolean, false),
});
