import { ChangeEvent, useCallback } from "react";
import { observer } from "mobx-react-lite";

interface IProps {
  loadFile(file: File): void;
}

export const RightSide = observer(function RightSide(props: IProps) {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files.item(0);
      if (file) {
        props.loadFile(file);
      }
    }
  }, []);

  return (
    <div>
      <input
        type={"file"}
        accept={".gcs"}
        multiple={false}
        onChange={onChange}
      ></input>
    </div>
  );
});
