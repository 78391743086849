import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Table } from "../Elements/Table/Table";
import { ObservableMap } from "mobx";
import { Td } from "../Elements/Table/Td";
import { observer } from "mobx-react-lite";

const ConditionalModifiersRow = observer(
  function ConditionalModifiersRow(props: { name: string; value: number }) {
    return (
      <Tr>
        <Td bold textAlign={"right"}>
          {props.value >= 0 ? `+${props.value}` : props.value}
        </Td>
        <Td bold>{props.name}</Td>
      </Tr>
    );
  },
);

const ConditionalModifiersRenderer = observer(
  function ConditionalModifiersRenderer(props: {
    data: ObservableMap<string, number>;
  }) {
    return Array.from(props.data.entries()).map(([key, value]) => {
      return <ConditionalModifiersRow key={key} name={key} value={value} />;
    });
  },
);

export const ConditionalModifiersContainer = observer(
  function ConditionalModifiersContainer(props: {
    data: ObservableMap<string, number>;
  }) {
    if (props.data.size <= 0) {
      return null;
    }

    return (
      <Table style={{ gridArea: "conditional_modifiers" }}>
        <thead>
          <Tr>
            <Th>±</Th>
            <Th>Condition</Th>
          </Tr>
        </thead>
        <tbody>
          <ConditionalModifiersRenderer data={props.data} />
        </tbody>
      </Table>
    );
  },
);
