import * as styles from "./AttributeRow.module.less";
import { observer } from "mobx-react-lite";
import { NumberInput } from "./NumberInput";

interface IProps {
  fullName?: string;
  name: string;
  value: number;
  setValue(value: number | undefined): void;
  points: number;
}
export const AttributeRow = observer(function AttributeRow(props: IProps) {
  let name = props.name;
  if (props.fullName) {
    name = `${props.fullName} (${props.name})`;
  }

  return (
    <tr>
      <td className={styles.points}>[{props.points}]</td>
      <td className={styles.value}>
        <NumberInput onChange={props.setValue} value={props.value} />
      </td>
      <td className={styles.name}>{name}</td>
    </tr>
  );
});
