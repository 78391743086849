import { Box } from "../Elements/Box";
import { Row } from "../Elements/Row";
import { Instance } from "mobx-state-tree";

import { ProfileStore } from "../Store/ProfileStore";
import { observer } from "mobx-react-lite";

interface IProps {
  profileData: Instance<typeof ProfileStore>;
}

export const Description = observer(function Description(props: IProps) {
  return (
    <Box
      name={"Description"}
      style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
    >
      <table>
        <tbody>
          <Row
            name={"Gender"}
            value={props.profileData.gender}
            onChange={props.profileData.setGender}
          />
          <Row
            name={"Age"}
            value={props.profileData.age}
            onChange={props.profileData.setAge}
          />
          <Row
            name={"Birthday"}
            value={props.profileData.birthday}
            onChange={props.profileData.setBirthday}
          />
          <Row
            name={"Religion"}
            value={props.profileData.religion}
            onChange={props.profileData.setReligion}
          />
        </tbody>
      </table>
      <table>
        <tbody>
          <Row
            name={"Height"}
            value={props.profileData.heightCm}
            onChange={props.profileData.setHeightCm}
          />
          <Row
            name={"Weight"}
            value={props.profileData.weightKg}
            onChange={props.profileData.setWeightKg}
          />
          <Row
            name={"Size"}
            value={props.profileData.sizeSigned}
            onChange={props.profileData.setSizeSigned}
          />
          <Row
            name={"TL"}
            value={props.profileData.tech_level}
            onChange={props.profileData.setTechLevel}
          />
        </tbody>
      </table>
      <table>
        <tbody>
          <Row
            name={"Hair"}
            value={props.profileData.hair}
            onChange={props.profileData.setHair}
          />
          <Row
            name={"Eyes"}
            value={props.profileData.eyes}
            onChange={props.profileData.setEyes}
          />
          <Row
            name={"Skin"}
            value={props.profileData.skin}
            onChange={props.profileData.setSkin}
          />
          <Row
            name={"Hand"}
            value={props.profileData.handedness}
            onChange={props.profileData.setHandedness}
          />
        </tbody>
      </table>
    </Box>
  );
});
