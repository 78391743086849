.container {
  background-color: #2b2b2b;
  color: white;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  height: 17px;
  font-weight: inherit;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
}
