import { types } from "mobx-state-tree";
import { WeaponUnion } from "./WeaponUnion";

export const TechniqueStore = types.model("TechniqueStore", {
  id: types.string,
  type: types.literal("technique"),
  name: types.string,
  reference: types.string,
  notes: types.maybe(types.string),
  tags: types.array(types.string),
  difficulty: types.string,
  points: types.optional(types.number, 0),
  default: types.model({
    type: types.string,
    name: types.maybe(types.string),
    modifier: types.maybe(types.number),
  }),
  limit: types.number,
  weapons: types.array(WeaponUnion),
  calc: types.maybe(
    types.model({
      level: types.maybe(types.number),
      rsl: types.maybe(types.string),
    }),
  ),
});
