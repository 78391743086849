import { types } from "mobx-state-tree";
import { FeatureUnion } from "./Feature/FeatureUnion";

export const SkillStore = types.model("SkillStore", {
  id: types.string,
  type: types.literal("skill"),
  name: types.maybe(types.string),
  reference: types.maybe(types.string),
  notes: types.maybe(types.string),
  tags: types.array(types.string),
  tech_level: types.maybe(types.string),
  difficulty: types.string,
  points: types.optional(types.number, 0),
  defaulted_from: types.maybe(
    types.model({
      type: types.string,
      modifier: types.number,
      level: types.number,
      adjusted_level: types.number,
      points: types.maybe(types.number),
    }),
  ),
  defaults: types.array(
    types.model({
      type: types.string,
      name: types.maybe(types.string),
      modifier: types.maybe(types.number),
      specialization: types.maybe(types.string),
    }),
  ),
  features: types.array(FeatureUnion),
  calc: types.maybe(
    types.model({
      level: types.maybe(types.number),
      rsl: types.maybe(types.string),
    }),
  ),
});
