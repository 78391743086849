import { Instance } from "mobx-state-tree";
import { MainStore } from "../Store/MainStore";
import { autorun } from "mobx";
import { EquipmentStore } from "../Store/EquipmentStore";
import { EquipmentContainerStore } from "../Store/EquipmentContainerStore";
import { EquipmentUnion } from "../Store/EquipmentUnion";

function setEquipmentWeights(equipment: Instance<typeof EquipmentStore>) {
  const weight = parseFloat(equipment.weight || "0");
  const quantity = equipment.quantity;
  const extendedWeight = weight * quantity + " lb";
  equipment.calc.setExtendedWeight(extendedWeight);
  if (!equipment.ignore_weight_for_skills) {
    equipment.calc.setExtendedWeightForSkills(extendedWeight);
  }

  const value = equipment.value;
  equipment.calc.setExtendedValue(value * quantity);
}

function setEquipmentContainerWeights(
  equipmentContainer: Instance<typeof EquipmentContainerStore>,
) {
  for (const equipment of equipmentContainer.children || []) {
    if (equipment.type === "equipment") {
      setEquipmentWeights(equipment);
    }
    if (equipment.type === "equipment_container") {
      setEquipmentContainerWeights(equipment);
    }
  }

  const selfWeight = parseFloat(equipmentContainer.weight || "0");
  const sumWeight = equipmentContainer.children.reduce(
    (acc, cur: Instance<typeof EquipmentUnion>) => {
      acc += parseFloat(cur.calc.extended_weight);
      return acc;
    },
    0,
  );
  const quantity = equipmentContainer.quantity;
  const extendedWeight = (selfWeight + sumWeight) * quantity + " lb";
  equipmentContainer.calc.setExtendedWeight(extendedWeight);
  if (!equipmentContainer.ignore_weight_for_skills) {
    equipmentContainer.calc.setExtendedWeightForSkills(extendedWeight);
  }

  const selfValue = equipmentContainer.value;
  const sumValue = equipmentContainer.children.reduce(
    (acc, cur: Instance<typeof EquipmentUnion>) => {
      acc += cur.calc.extended_value;
      return acc;
    },
    0,
  );
  equipmentContainer.calc.setExtendedValue((selfValue + sumValue) * quantity);
}

export function equipments(store: Instance<typeof MainStore>) {
  autorun(() => {
    const gcsStore = store.data;
    if (!gcsStore) {
      return;
    }

    for (const equipment of store.data?.equipment || []) {
      if (equipment.type === "equipment") {
        setEquipmentWeights(equipment);
      }
      if (equipment.type === "equipment_container") {
        setEquipmentContainerWeights(equipment);
      }
    }

    for (const equipment of store.data?.other_equipment || []) {
      if (equipment.type === "equipment") {
        setEquipmentWeights(equipment);
      }
      if (equipment.type === "equipment_container") {
        setEquipmentContainerWeights(equipment);
      }
    }
  });
}
