.container {
  display: grid;
  grid-template-rows: 17px 1fr;
  border: 1px solid #2b2b2b;
}

.name {
  background-color: #2b2b2b;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
}
