import { types } from "mobx-state-tree";
import { action } from "mobx";

export const ProfileStore = types
  .model("ProfileStore", {
    size: types.maybe(types.number), // TODO
    name: types.string,
    age: types.maybe(types.string),
    birthday: types.maybe(types.string),
    eyes: types.string,
    hair: types.string,
    skin: types.string,
    handedness: types.string,
    gender: types.string,
    height: types.string,
    weight: types.string,
    player_name: types.string,
    title: types.maybe(types.string),
    organization: types.maybe(types.string),
    tech_level: types.string,
    portrait: types.string,
    religion: types.maybe(types.string), // TODO
    SM: types.maybe(types.number),
  })
  .views((self) => ({
    get weightKg(): string {
      return parseFloat(self.weight) / 2 + " kg";
    },
    get heightCm(): string {
      const FEET_TO_CM = 33.33;
      const INCHES_TO_CM = FEET_TO_CM / 12;
      const [feet, inches] = self.height.split("'");
      const cmValue =
        parseFloat(feet) * FEET_TO_CM + parseFloat(inches) * INCHES_TO_CM;
      return `${cmValue.toFixed(0)} cm`;
    },
    get sizeSigned(): string {
      const input = self.size || 0;
      return input >= 0 ? `+${input}` : `${input}`;
    },
  }))
  .actions((self) => ({
    setName: (name: string | undefined) => (self.name = name || ""),
    setTitle: (title: string | undefined) => (self.title = title),
    setOrganization: (organization: string | undefined) =>
      (self.organization = organization),
    setPlayerName: (value: string | undefined) =>
      (self.player_name = value || ""),
    setGender: (value: string | undefined) => (self.gender = value || ""),
    setAge: (value: string | undefined) => (self.age = value),
    setBirthday: (value: string | undefined) => (self.birthday = value),
    setReligion: (value: string | undefined) => (self.religion = value),
    setHeightCm: (value: string | undefined) => {
      // TODO
    },
    setWeightKg: (value: string | undefined) => {
      // TODO
    },
    setSizeSigned: (value: string | undefined) => {
      self.size = Number(value);
    },
    setEyes: (value: string | undefined) => (self.eyes = value || ""),
    setHair: (value: string | undefined) => (self.hair = value || ""),
    setSkin: (value: string | undefined) => (self.skin = value || ""),
    setHandedness: (value: string | undefined) =>
      (self.handedness = value || ""),
    setTechLevel: (value: string | undefined) =>
      (self.tech_level = value || ""),
    setPortrait: (value: string | undefined) => (self.portrait = value || ""),
    setSM: (value: number | undefined) => (self.SM = value),
  }));
