import { CSSProperties, PropsWithChildren } from "react";
import * as styles from "./Td.module.less";
import { observer } from "mobx-react-lite";
interface IProps {
  style?: CSSProperties;
  textAlign?: "left" | "center" | "right";
  color?: "default" | "editable" | "notEditable";
  bold?: boolean;
}
export const Td = observer(function Td(props: PropsWithChildren<IProps>) {
  let colorClass = "";
  if (props.color == "editable") {
    colorClass = styles.editableColor;
  }
  if (props.color == "notEditable") {
    colorClass = styles.notEditableColor;
  }
  return (
    <td
      className={styles.container + " " + colorClass}
      style={{
        ...props.style,
        textAlign: props.textAlign,
        fontWeight: props.bold ? "bold" : undefined,
        verticalAlign: "top",
      }}
    >
      {props.children}
    </td>
  );
});
