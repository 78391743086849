import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Th } from "../Elements/Table/Th";
import { Instance } from "mobx-state-tree";
import { Td } from "../Elements/Table/Td";
import { Fragment } from "react";
import { SkillUnion } from "../Store/SkillUnion";
import { SkillContainerStore } from "../Store/SkillContainerStore";
import { SkillStore } from "../Store/SkillStore";
import { TechniqueStore } from "../Store/TechniqueStore";
import { observer } from "mobx-react-lite";
import { fixReference } from "../functions/fixReference";

const SkillRow = observer(function SkillRow(props: {
  skill: Instance<typeof SkillStore> | Instance<typeof TechniqueStore>;
  level: number;
}) {
  return (
    <Tr>
      <Td style={{ paddingLeft: 8 * props.level }}>
        <b>{props.skill.name}</b>
        <br />
        {props.skill.notes}
      </Td>
      <Td>
        <b>{props.skill.calc?.level}</b>
      </Td>
      <Td>
        <b>{props.skill.calc?.rsl}</b>
      </Td>
      <Td>
        <b>{props.skill.points}</b>
      </Td>
      <Td>
        <b>{fixReference(props.skill.reference)}</b>
      </Td>
    </Tr>
  );
});

const SkillContainerRow = observer(function SkillContainerRow(props: {
  skill: Instance<typeof SkillContainerStore>;
  level: number;
}) {
  return (
    <Tr>
      <Td style={{ paddingLeft: 8 * props.level }}>
        <b>{props.skill.name}</b>
        <br />
        {props.skill.notes}
      </Td>
      <Td></Td>
      <Td></Td>
      <Td>
        <b>{props.skill.points}</b>
      </Td>
      <Td>
        <b>{fixReference(props.skill.reference)}</b>
      </Td>
    </Tr>
  );
});

const SkillRenderer = observer(function SkillRenderer(props: {
  data: Array<Instance<typeof SkillUnion>>;
  level: number;
}) {
  const rows = props.data.map((elem) => {
    if (elem.type === "skill" || elem.type === "technique") {
      return <SkillRow key={elem.id} skill={elem} level={props.level} />;
    }
    return (
      <Fragment key={elem.id}>
        <SkillContainerRow skill={elem} level={props.level} />
        <SkillRenderer data={elem.children} level={props.level + 1} />
      </Fragment>
    );
  });
  return <>{rows}</>;
});

export const SkillsContainer = observer(function SkillsContainer(props: {
  data: Array<Instance<typeof SkillUnion>>;
}) {
  if (props.data.length <= 0) {
    return null;
  }

  return (
    <Table style={{ gridArea: "skills" }}>
      <thead>
        <Tr>
          <Th>Skill / Technique</Th>
          <Th>SL</Th>
          <Th>RSL</Th>
          <Th>Pts</Th>
          <Th>X</Th>
        </Tr>
      </thead>
      <tbody>
        <SkillRenderer data={props.data} level={0} />
      </tbody>
    </Table>
  );
});
