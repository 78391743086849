import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Td } from "../Elements/Table/Td";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof GcsStore>;
}
export const BasicDamage = observer(function BasicDamage(props: IProps) {
  return (
    <Table name={"Basic Damage"}>
      <tbody>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.calc.thrust}
          </Td>
          <Td>Basic Thrust</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.calc.swing}
          </Td>
          <Td>Basic Swing</Td>
        </Tr>
      </tbody>
    </Table>
  );
});
