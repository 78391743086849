import { getParent, Instance, types } from "mobx-state-tree";
import { SettingsAttributeUnion } from "./SettingsAttribute/SettingsAttributeUnion";
import { GcsStore } from "./GcsStore";
import { evaluateExpression } from "../functions/evaluateExpression";
import { AttributeCalcStore } from "./AttributeCalcStore";

export const AttributeStore = types
  .model("AttributeStore", {
    attr_id: types.string,
    adj: types.number,
    damage: types.maybe(types.number),
    calc: AttributeCalcStore,
  })
  .views((self) => ({
    get settings(): undefined | Instance<typeof SettingsAttributeUnion> {
      const gcsStore = getParent<Instance<typeof GcsStore>>(self, 2);
      return gcsStore.settings.attributesMap.get(self.attr_id);
    },
  }))
  .views((self) => ({
    get currentThreshold(): undefined | string {
      const gcsStore = getParent<Instance<typeof GcsStore>>(self, 2);
      const current = self.calc.current || 0;
      const fetchVariable: (id: string) => number = (id) => {
        return gcsStore.attributesMap.get(id)?.calc.value || 0;
      };
      if (self.settings && self.settings.type === "pool") {
        const thresholds = self.settings.thresholds;
        for (const threshold of thresholds) {
          const calculatedValue = evaluateExpression(
            threshold.expression,
            fetchVariable,
          );
          if (current <= calculatedValue) {
            return threshold.state;
          }
        }
      }
      return undefined;
    },
  }))
  .actions((self) => ({
    setValue: (value: number) => {
      const currentValue = self.calc.value || 0;
      self.adj = self.adj + (value - currentValue);
    },
  }));
