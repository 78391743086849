import { ChangeEvent, useCallback, useEffect, useState } from "react";

interface IProps {
  onChange: (value: number | undefined) => void;
  value: number | undefined;
}

export function NumberInput(props: IProps) {
  const [value, setValue] = useState(String(props.value));

  useEffect(() => {
    setValue(String(props.value));
  }, [props.value]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [props.onChange],
  );

  const onBlur = useCallback(() => {
    props.onChange(Number(value));
  }, [value]);

  return (
    <input
      style={{
        border: "none",
        borderBottom: "1px solid",
        color: "inherit",
        fontWeight: "bold",
        width: "28px",
        textAlign: "right",
      }}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
}
