import * as styles from "./AttributeRow.module.less";
import { observer } from "mobx-react-lite";
import { NumberInput } from "./NumberInput";

interface IProps {
  fullName?: string;
  name: string;
  value: number | undefined;
  current: number | undefined;
  points: number;
  threshold?: string;
  onChangeValue: (value: number | undefined) => void;
  onChangeCurrent: (value: number | undefined) => void;
}
export const PointPoolRow = observer(function PointPoolRow(props: IProps) {
  return (
    <tr>
      <td className={styles.points}>[{props.points}]</td>
      <td className={styles.value}>
        <NumberInput onChange={props.onChangeCurrent} value={props.current} />
      </td>
      <td>of</td>
      <td className={styles.value}>
        <NumberInput onChange={props.onChangeValue} value={props.value} />
      </td>
      <td>{props.name}</td>
      <td>[{props.threshold || "UNKNOWN"}]</td>
    </tr>
  );
});
