import { Box } from "../Elements/Box";
import { Row } from "../Elements/Row";
import { observer } from "mobx-react-lite";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";

interface IProps {
  store: Instance<typeof GcsStore>;
}

export const Miscellaneous = observer(function Miscellaneous(props: IProps) {
  const createDate = new Date(props.store.created_date);
  const modifiedDate = new Date(props.store.modified_date);

  const readableCreateDate =
    createDate.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }) +
    ", " +
    createDate.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });
  const readableModifiedDate =
    modifiedDate.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }) +
    ", " +
    modifiedDate.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });

  return (
    <Box name={"Miscellaneous"}>
      <table>
        <tbody>
          <Row name={"Created"} value={readableCreateDate} />
          <Row name={"Modified"} value={readableModifiedDate} />
          <Row
            name={"Player"}
            value={props.store.profile.player_name}
            onChange={props.store.profile.setPlayerName}
          />
        </tbody>
      </table>
    </Box>
  );
});
