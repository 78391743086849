import { GcsStore } from "../Store/GcsStore";
import { Instance } from "mobx-state-tree";
import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Td } from "../Elements/Table/Td";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof GcsStore>;
}

export const Points = observer(function Points(props: IProps) {
  return (
    <Table name={`${props.store.total_points} Points`}>
      <tbody>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.unspentPoints}
          </Td>
          <Td>Unspent</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            0
          </Td>
          <Td>Ancestry</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.spentPointsAttributes}
          </Td>
          <Td>Attributes</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.spentPointsTraitsAdvantages}
          </Td>
          <Td>Advantages</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.spentPointsTraitsDisadvantages}
          </Td>
          <Td>Disadvantages</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            0
          </Td>
          <Td>Quirks</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.spentPointsSkills}
          </Td>
          <Td>Skills</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {props.store.spentPointsSpells}
          </Td>
          <Td>Spells</Td>
        </Tr>
      </tbody>
    </Table>
  );
});
