.container {
}

.name {
  text-align: left;
}

.value {
  color: #0000a0;
  font-weight: 500;
  text-align: right;
}

.points {
  color: #0000a099;
  text-align: right;
}
