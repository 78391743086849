import { Box } from "../Elements/Box";
import { Table } from "../Elements/Table/Table";
import { Tr } from "../Elements/Table/Tr";
import { Td } from "../Elements/Table/Td";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { observer } from "mobx-react-lite";
import { numberToString } from "../functions/numberToString";

interface IProps {
  store: Instance<typeof GcsStore>;
}
export const LiftingMoving = observer(function LiftingMoving(props: IProps) {
  return (
    <Table name={"Lifting & Moving Things"}>
      <tbody>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {numberToString(props.store.calc.basicLiftKg)} kg
          </Td>
          <Td>Basic Lift</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {numberToString(props.store.calc.oneHandedLiftKg)} kg
          </Td>
          <Td>One-Handed Lift</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {numberToString(props.store.calc.twoHandedLiftKg)} kg
          </Td>
          <Td>Two-Handed Lift</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {numberToString(props.store.calc.shoveKnockOverKg)} kg
          </Td>
          <Td>Shove & Knock Over</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {numberToString(props.store.calc.runningShoveKnockOverKg)} kg
          </Td>
          <Td>Running Shove & Knock Over</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {numberToString(props.store.calc.carryOnBackKg)} kg
          </Td>
          <Td>Carry On Back</Td>
        </Tr>
        <Tr>
          <Td textAlign={"right"} bold color={"notEditable"}>
            {numberToString(props.store.calc.shiftSlightlyKg)} kg
          </Td>
          <Td>Shift Slightly</Td>
        </Tr>
      </tbody>
    </Table>
  );
});
