import { Box } from "../Elements/Box";
import { AttributeRow } from "../Elements/AttributeRow";
import { Instance } from "mobx-state-tree";
import { GcsStore } from "../Store/GcsStore";
import { ReactElement } from "react";
import { observer } from "mobx-react-lite";

interface IProps {
  store: Instance<typeof GcsStore>;
}
export const PrimaryAttributes = observer(function PrimaryAttributes(
  props: IProps,
) {
  const attributes = props.store.attributes.reduce<ReactElement[]>(
    (acc, cur) => {
      const settingsAttribute = cur.settings;
      if (settingsAttribute?.isPrimary) {
        acc.push(
          <AttributeRow
            key={cur.attr_id}
            fullName={settingsAttribute.full_name}
            name={settingsAttribute.name}
            value={cur.calc.value}
            setValue={cur.setValue}
            points={cur.calc.points}
          />,
        );
      }
      return acc;
    },
    [],
  );

  return (
    <Box name={"Primary Attribute"}>
      <table>
        <tbody>{attributes}</tbody>
      </table>
    </Box>
  );
});
